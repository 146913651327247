@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Raleway', sans-serif;
}

body,
html,
#root {
  padding: 0;
  margin: 0;
}

:root {
  --primary: #fbc10d;
  --secondary: #fdc70c;
  --blue: #388db3;
  --white: #ffffff;
  --light-gray: #c7c5c5;
  --gray: #9b9a9a;
  --dark-gray: #303030;
  --black: #151515;
}

@font-face {
  font-family: "Heading";
  src: url("assets/HeadingNowTrial-57Extrabold.ttf");
}

.bg-primary {
  background-color: var(--primary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-yellow {
  background-color: var(--yellow);
}

.bg-white {
  background-color: var(--white);
}

.bg-gray {
  background-color: var(--gray);
}

.bg-dark-gray {
  background-color: var(--dark-gray);
}

.bg-black {
  background-color: var(--black);
}

.color-primary {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.color-yellow {
  color: var(--yellow);
}

.color-white {
  color: var(--white);
}

.color-light-gray {
  color: var(--light-gray);
}

.color-gray {
  color: var(--gray);
}

.color-dark-gray {
  color: var(--dark-gray);
}

.color-black {
  color: var(--black);
}

.pointer:hover {
  cursor: pointer !important;
}

.semibold {
  font-weight: 500 !important;
}

.container-16-9 {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  height: 100%;
}

.h-full-important {
  height: 100% !important;
}

.card-servico {
  transition: ease-in 0.3s;
}

.card-servico-imagem {
  background-position: center;
  background-size: cover;
  color: white;
}

.card-servico:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 720px) {
  #imgnav {
    width: 55%;
    height: auto;
  }
}